<template>
  <q-layout view="hHh LpR fFf" class="movezero">
    <q-page-container>
      <q-page>
        <div class="row absolute-full">
          <div
            class="col col-md-4 col-lg-4 full-height q-pa-lg text-center loginBg column justify-center"
          >
            <div class="q-px-xl">
              <img
                :src="require('assets/MoveZero Logo.svg')"
                class="logo q-mb-md cursor-pointer"
                @click="$router.push({ path: '/' })"
              >
              <router-view />
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'LayoutLogin'
}
</script>

<style lang="stylus">
// The login.styl wouldn't work so I put this here, hate me all you want but it works.
.movezero
  .q-field__control
    background rgba(0,0,0,0.05)
</style>

<style lang="stylus" scoped>
html {
  background-color: #FFDA40;
}

.q-page
  background-image url('~assets/Scooter.png')
  background-repeat no-repeat
  background-position center center
  background-size cover

.loginBg {
  background-image linear-gradient(to bottom, #FFF3BF -100%, #FFDA40 50%, rgba(#FFDA40, 90%) 100%)
}

.logo {
  width: 70%;
  max-width: 300px;
}
</style>
